<template>
  <div class="brands-wrapper my-8 lg:my-10">
    <div v-if="blok.blocks" class="container desktop">
      <div
        v-html="$storyapi.richTextResolver.render(blok.title)"
        class="mont my-6 text-2xl lg:text-4xl text-brownish-grey"
      ></div>
      <div class="grid grid-cols-4 gap-8">
        <div v-for="blok in blok.blocks" :key="blok.index">
          <OurBrandsBlock :blok="blok" />
        </div>
      </div>
    </div>
    <div v-if="blok.blocks" class="container mobile">
      <div
        v-html="$storyapi.richTextResolver.render(blok.title)"
        class="mont title mb-8 text-2xl text-brownish-grey"
      ></div>
      <VueSlickCarousel v-bind="settings">
        <div v-for="item in blok.blocks" :key="item.index" aria-hidden="true">
          <OurBrandsBlock :blok="item" />
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import { ref } from "@nuxtjs/composition-api";
import OurBrandsBlock from "./OurBrandsBlock";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "OurBrandsSection",
  components: {
    OurBrandsBlock,
    VueSlickCarousel,
  },
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    const settings = ref({
      infinite: true,
      arrows: false,
      dots: false,
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            draggable: true,
            slidesToShow: 1,
            autoplay: true,
          },
        },
      ],
    });
    return {
      settings,
    };
  },
};
</script>
<style lang="scss" scoped>
.desktop {
  display: block;
  min-height: 490px;
}
.mobile {
  display: none;
}
@media all and (max-width: 480px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .mobile ::v-deep .slick-slider {
    overflow: hidden;
    margin: 0 -15px;
    .slick-list {
      .slick-track {
        display: flex;
        .slick-slide {
          & > div {
            margin-bottom: 15px;
          }
        }
      }
    }
    .slick-slide {
      margin: 0 15px;
    }
  }
}
</style>
