<template>
  <section
    class="bgd-image container rounded-xl"
    :style="{
      background: 'url(' + blok.image.filename + '/m/' + ')',
    }"
  ></section>
</template>

<script>
export default {
  name: "ImageComp",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.bgd-image {
  height: 598px;
  background: #e8e8e8 0% 0% no-repeat padding-box;
  background-position: center !important;
}
</style>
