var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-comp"},[_c('div',{staticClass:"left"},[_c('h2',{staticClass:"title font-mont"},[_vm._v(_vm._s(_vm.blok.login_title))]),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.showLogin)?_c('div',{key:"login",staticClass:"login"},[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('form',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element custom-input",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email address')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element custom-input",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn",attrs:{"disabled":invalid},on:{"click":function($event){$event.preventDefault();validate().then(_vm.onLoginFormSubmit())}}},[_vm._v("\n                "+_vm._s(_vm.$t("Login"))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"link font-fgb",on:{"click":function($event){_vm.showLogin = !_vm.showLogin}}},[_vm._v("\n                "+_vm._s(_vm.$t("Request password reset"))+"\n              ")])])],1)]}}],null,false,93860656)})],1):_c('div',{key:"forgotpass",staticClass:"forgot-pass"},[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('form',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element custom-input",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email address')},model:{value:(_vm.formForgot.email),callback:function ($$v) {_vm.$set(_vm.formForgot, "email", $$v)},expression:"formForgot.email"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn",attrs:{"disabled":invalid},on:{"click":function($event){$event.preventDefault();validate().then(_vm.onForgotPasswordFormSubmit())}}},[_vm._v("\n                "+_vm._s(_vm.$t("Send request"))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"link font-mont",on:{"click":function($event){_vm.showLogin = !_vm.showLogin}}},[_vm._v("\n                "+_vm._s(_vm.$t("Go back"))+"\n              ")])])],1)]}}])})],1)])],1),_vm._v(" "),_c('div',{staticClass:"right"},[_c('h2',{staticClass:"title font-mont"},[_vm._v(_vm._s(_vm.blok.register_title))]),_vm._v(" "),_c('p',{staticClass:"desc font-fgb"},[_vm._v(_vm._s(_vm.blok.register_description))]),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":function($event){_vm.$router.push(_vm.localePath('/register'))}}},[_vm._v("\n      "+_vm._s(_vm.$t("Go to registration form"))+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }