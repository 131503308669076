<template>
  <div class="relative">
    <div v-if="blok.link">
      <nuxt-link
        v-if="convertToNuxtLink(blok.link.url)"
        :to="convertToNuxtLink(blok.link.url)"
        aria-label="Our brand link"
      >
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image.filename + '/m/filters:format(webp)'"
          class="object-fill w-full rounded-xl"
          alt="brand image"
          width="359"
          height="239"
          loading="lazy"
        />
      </nuxt-link>
      <a v-else :href="blok.link.url" aria-label="Our brand link">
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image.filename + '/m/filters:format(webp)'"
          class="object-fill w-full rounded-xl"
          alt="brand image"
          width="359"
          height="239"
          loading="lazy"
        />
      </a>
    </div>
    <div v-else>
      <nuxt-img
        quality="90"
        format="webp"
        placeholder
        :src="blok.image.filename + '/m/filters:format(webp)'"
        class="object-fill w-full rounded-xl hover:cursor-pointer"
        alt="brand image"
        width="359"
        height="239"
        loading="lazy"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "OurBrandsBlock",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  methods: {
    convertToNuxtLink(url) {
      // Extract path and query parameters from the URL
      const urlObj = new URL(url);

      const path = urlObj.pathname;
      const query = urlObj.searchParams.toString();

      if (urlObj.origin.includes("shots")) {
        const nuxtLink = `${path}${query ? `?${query}` : ""}`;

        return nuxtLink;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss"></style>
