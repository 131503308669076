
























































































import CustomInput from "~/components/Custom/CustomInput.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {
  ref,
  useContext,
  useRouter,
  computed,
  onMounted,
} from "@nuxtjs/composition-api";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useForgotPassword } from "~/modules/customer/composables/useForgotPassword";
import { useUiNotification } from "~/composables";
import useUserCustomAtr from "~/modules/customer/composables/useUserCustomAtr";
import { AvailableStores, useStore } from "~/composables";

extend("email", {
  ...email,
  message: "Invalid email",
});

extend("required", {
  ...required,
  message: "This field is required",
});

const customerPasswordRegExp = /^(?=.*[A-Z])(?=.*\d).+$/;

extend("password", {
  message: "Please use one uppercase and one number in your password.",
  validate: (value) => customerPasswordRegExp.test(value),
});

export default {
  components: {
    CustomInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    const form = ref({
      email: "",
      password: "",
    });
    const formForgot = ref({
      email: "",
    });
    const showLogin = ref(true);
    const { load: loadCart } = useCart();
    const { loadItemsCount } = useWishlist();
    const { login, error: userError, userAdv } = useUser();
    const { request: resetPassword, error: forgotPasswordError } =
      useForgotPassword();
    const router = useRouter();
    const { send: sendNotification } = useUiNotification();
    const breadcrumbs = ref({});
    const { getData } = useUserCustomAtr();

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config, redirect } = useContext();
    const isRecaptchaEnabled = ref<boolean>(
      typeof $recaptcha !== "undefined" && Boolean($config.isRecaptcha)
    );

    const getRecaptchaInfo = async (isRecaptchaOn) => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(),
          cleanup: () => {
            $recaptcha.reset();
          },
        };
      }
      return { token: null, cleanup: () => {} };
    };

    const { stores, change: changeStore, load: loadStores } = useStore();

    const availableStores = computed<AvailableStores>(() => stores.value ?? []);

    const onLoginFormSubmit = async () => {
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value
      );
      await login({
        user: { ...form.value, ...(token ? { recaptchaToken: token } : {}) },
      });

      if (!userError.value.login) {
        await getData();

        const currentStore = app.$cookies.get("vsf-locale");

        if (
          userAdv?.value?.preferred_store &&
          userAdv?.value?.preferred_store !== "null" &&
          userAdv?.value?.preferred_store !== currentStore
        ) {
          const store = availableStores.value.find(
            (s) => s.store_code === userAdv?.value?.preferred_store
          );

          app.$vsf.$magento.config.state.setStore(store.store_code);
          app.$vsf.$magento.config.state.setCurrency(
            store.default_display_currency_code
          );
          app.$vsf.$magento.config.state.setLocale(store.store_code);
          const newStoreUrl = app.switchLocalePath(store.store_code);
          window.location.replace("/" + userAdv?.value?.preferred_store + "/");
        } else {
          await Promise.all([loadItemsCount(), loadCart()]);
          sendNotification({
            id: Symbol("user_logged"),
            message: app.i18n.t("Logged In") as string,
            type: "success",
            icon: "check",
            persist: false,
            title: "Logged in",
          });
          router.replace(app.localeRoute("/"));
        }
      }
    };

    const onForgotPasswordFormSubmit = async () => {
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value
      );
      await resetPassword({
        email: formForgot.value.email,
        ...(token ? { recaptchaToken: token } : {}),
      });
      cleanup();

      sendNotification({
        id: Symbol("user_forgot"),
        message: app.i18n.t("Password reset email is sent") as string,
        type: "success",
        icon: "check",
        persist: false,
        title: "Password reset",
      });
    };

    onMounted(() => {
      if (stores.value && stores.value?.length) return;
      loadStores();
    });

    return {
      form,
      formForgot,
      showLogin,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit,
      breadcrumbs,
    };
  },
};
