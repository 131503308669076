<template>
  <div class="my-10 container">
    <div class="lg:flex">
      <div class="xl:px-32 xl:py-20 md:px-2 md:py-2 ourteam-text">
        <div
          v-html="$storyapi.richTextResolver.render(blok.title)"
          class="text-red-title text-4xl md:mb-8 mb-4 font-mont"
        />
        <div
          v-html="$storyapi.richTextResolver.render(blok.text)"
          class="font-fgb richtext-paragraph text-lg"
        />
      </div>
      <div class="ourteam-img-wrapper">
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image.filename + '/m/fit-in/734x536/filters:format(webp)'"
          class="ourteam-img rounded-xl"
          alt="Our team"
          width="734"
          height="536"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurTeam",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  @apply my-10;
  @include for-mobile {
    @apply px-10;
  }
}
.ourteam-img-wrapper {
  flex-basis: 50%;
  padding-left: 2%;
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    margin-top: 10px;
  }
}
.ourteam-img {
  // border-top-left-radius: 25px;
  // border-bottom-left-radius: 25px;
  max-width: 100%;
  height: auto;
  /* max-height: 500px; */
  @media only screen and (max-width: 1023px) {
    @apply px-28;
  }
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
}
.ourteam-text {
  color: #261717;
  flex-basis: 50%;
  @media only screen and (max-width: 1023px) {
    @apply mb-8;
  }
  p {
    text-align: left;
    letter-spacing: 0px;
    line-height: 28px;
    font-size: 18px;
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
    color: #261717;
    opacity: 1;
    span {
      font-weight: bold;
    }
  }
}
.text-red-title {
}
</style>
